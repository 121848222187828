:root {
  --font-color-default: rgba(0, 0, 0, 0.85);
  --font-color-normal: rgba(0, 0, 0, 0.65);
  --font-color-grey: rgba(0, 0, 0, 0.45);
  --font-color-lightgrey: rgba(0, 0, 0, 0.25);
  --border-color-default: rgba(0, 0, 0, 0.08);
  --border-color-highlight: rgba(0, 0, 0, 0.13);
  --primary-color: #13C5CE;
  --primary-color-hover: #00BAC8;
  --secondary-color: rgba(237, 66, 75, 0.1);
  --grey-color: rgba(0, 0, 0, 0.45);
  --lightgrey-color: #B9BEC1;
  --error-color: #F55F4E;
  --success-color: #46DB7A;
  --warn-color: #F5894E;
  --info-color: #4EBBF5;
  --bg-color-default: #F5F7F8;
  --bg-color-highlight: #E7F9FA;
  --bg-color-black: rgba(0, 0, 0, 0.85);
}

.font-color-default {
  color: var(--font-color-default);
}
.font-color-normal {
  color: var(--font-color-normal);
}
.font-color-grey {
  color: var(--font-color-grey);
}
.font-color-lightgrey {
  color: var(--font-color-lightgrey);
}
.font-color-primary {
  color: var(--primary-color);
}
.font-color-secondary {
  color: var(--secondary-color);
}
.font-color-error {
  color: var(--error-color);
}
.font-color-warn {
  color: var(--warn-color);
}
.font-color-success {
  color: var(--success-color);
}
.font-color-info {
  color: var(--info-color);
}

.border-color-default {
  border-color: var(--border-color-default);
}
.border-color-highlight {
  border-color: var(--border-color-highlight);
}

.bg-color-default {
  background-color: var(--bg-color-default);
}
.bg-color-highlight {
  background-color: var(--bg-color-highlight);
}
.bg-color-black {
  background-color: var(--bg-color-black);
}

.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-wrap {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .content-wrap {
    width: 740px
  }
}

@media (min-width: 992px) {
  .content-wrap {
    width: 960px
  }
}

@media (min-width: 1350px) {
  .content-wrap {
    width: 1160px
  }
}

.content-wrap-full {
  width: 100%;
}