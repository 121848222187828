.wrap {
	padding: 16px 24px;
}
.replyTo {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 12px;
	line-height: 22px;
	margin-bottom: 8px;
	color: #6f6f6f;
	font-size: 14px;
}
.replyToUser {
	color: #155BD4;
}
.box {
	display: flex;
	position: relative;
	vertical-align: top;
}
.textarea {
	border: 1px solid rgba(18,18,18,.12);
	border-radius: 24px;
	resize: none;
	width: 100%;
	padding: 12px 48px 12px 16px;
	font-size: 14px;
	line-height: 22px;
}
.textarea + .textarea {
	border: none;
}
.textarea::-webkit-scrollbar{
	display: none;
}
.textarea:focus {
	border-color: var(--primary-color);
	outline: 0;
}
.button {
	display: flex;
	position: absolute;
	right: 6px;
	bottom: 6px;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	background: var(--primary-color);
	cursor: pointer;
	z-index: 2;
	border: none;
	outline: none;
	justify-content: center;
	align-items: center;
}
.button:disabled {
	opacity: 0.3;
}
